import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"

function SEO({ description, lang, meta, keywords = [], title, image }) {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl: url
            defaultImage: image
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description || 'Prenájom karavanov a autostanov - požičovňa karavanov camprent. Offroadové karavany, ktoré Vám vyrazia dych. Rezervujte si karavan už dnes!';
  const metaTitle = title || site.siteMetadata.title
  const metaImage = `${site.siteMetadata.siteUrl}${image || site.siteMetadata.defaultImage}`
  const url = `${site.siteMetadata.siteUrl}${pathname}`

  const defaultKeywords = [
    "stany bushwaka",
    "bushwaka baby shark",
    "crawler trc 458",
    "crawler trc",
    "TRC karavany",
    "TRC",
    "TRC 458",
    "bushwaka",
    "karavany",
    "autostany",
    "stany na auto",
    "stany na prenájom",
    "prenájom karavanov",
    "prenajom karavanov",
    "pronajem karavanu",
    "pozicovna karavanov",
    "pozicovna karavanu",
    "pozicovna autostanov",
    "pozicovna autostanu",
    "prenajom autostanov",
    "požičovňa karavanov",
    "požičovňa autostanov",
    "požičovna autostanu",
    "dovolenka karavanom",
    "lacný prenájom karavanov",
    "karavany na prenájom",
    "prenájom obytných áut",
    "obytné prívesy",
    "rodinné karavany",
    "luxusné karavany",
    "prenájom karavanov na dovolenku",
    "prenájom karavanov Slovensko",
    "lacná požičovňa karavanov",
    "karavany na prenájom Bratislava",
    "karavany na prenájom Košice",
    "caravan rental",
    "RV rental",
    "motorhome rental",
    "cheap caravan rental",
    "holiday caravan",
    "caravan hire",
    "camper van rental",
    "family caravans",
    "luxury caravan hire",
    "motorhome hire",
    "affordable RV rentals",
    "best caravan rentals",
    "vacation caravans",
    "rental caravans near me",
    "weekend caravan hire",
    "karavany",
    "pronájem karavanů",
    "půjčovna karavanů",
    "dovolená karavanem",
    "levný pronájem karavanů",
    "karavany k pronájmu",
    "pronájem obytných vozů",
    "obytné přívěsy",
    "rodinné karavany",
    "luxusní karavany",
    "pronájem karavanů na dovolenou",
    "pronájem karavanů Česko",
    "levná půjčovna karavanů",
    "karavany k pronájmu Praha",
    "karavany k pronájmu Brno"
  ];

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `image`,
          content: metaImage,
        },
        {
          property: `og:url`,
          content: url,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: metaImage,
        },
        {
          name: `keywords`,
          content: (keywords.length > 0 ? keywords : defaultKeywords).join(`, `),
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `sk`,
  meta: [],
  keywords: [
    "stany bushwaka",
    "bushwaka baby shark",
    "crawler trc 458",
    "crawler trc",
    "TRC karavany",
    "TRC",
    "TRC 458",
    "bushwaka",
    "karavany",
    "autostany",
    "stany na auto",
    "stany na prenájom",
    "prenájom karavanov",
    "prenajom karavanov",
    "pronajem karavanu",
    "pozicovna karavanov",
    "pozicovna karavanu",
    "pozicovna autostanov",
    "pozicovna autostanu",
    "prenajom autostanov",
    "požičovňa karavanov",
    "požičovňa autostanov",
    "požičovna autostanu",
    "dovolenka karavanom",
    "lacný prenájom karavanov",
    "karavany na prenájom",
    "prenájom obytných áut",
    "obytné prívesy",
    "rodinné karavany",
    "luxusné karavany",
    "prenájom karavanov na dovolenku",
    "prenájom karavanov Slovensko",
    "lacná požičovňa karavanov",
    "karavany na prenájom Bratislava",
    "karavany na prenájom Košice",
    "caravan rental",
    "RV rental",
    "motorhome rental",
    "cheap caravan rental",
    "holiday caravan",
    "caravan hire",
    "camper van rental",
    "family caravans",
    "luxury caravan hire",
    "motorhome hire",
    "affordable RV rentals",
    "best caravan rentals",
    "vacation caravans",
    "rental caravans near me",
    "weekend caravan hire",
    "karavany",
    "pronájem karavanů",
    "půjčovna karavanů",
    "dovolená karavanem",
    "levný pronájem karavanů",
    "karavany k pronájmu",
    "pronájem obytných vozů",
    "obytné přívěsy",
    "rodinné karavany",
    "luxusní karavany",
    "pronájem karavanů na dovolenou",
    "pronájem karavanů Česko",
    "levná půjčovna karavanů",
    "karavany k pronájmu Praha",
    "karavany k pronájmu Brno"
  ],
  description: `Prenájom karavanov a autostanov - požičovňa karavanov camprent. Offroadové karavany, ktoré Vám vyrazia dych. Rezervujte si karavan už dnes!`,
  image: null,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
}

export default SEO
